import CryptoJS from "crypto-js";

// 將使用者資訊加密後存入 sessionStorage (key: userInfo)
export function encryptedUserInfo (key, info) {
  const sk = process.env.VUE_APP_SK;
  const iv = process.env.VUE_APP_SK;
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(info), sk, {iv: CryptoJS.enc.Utf8.parse(iv)});
  const ciphertext = encrypted.toString();
  window.sessionStorage.setItem(key, ciphertext);
  // console.log("加密後的使用者資訊:", ciphertext);
  return ciphertext;
}

// 將使用者資訊解密，並轉換成 JSON 格式
export function decryptMessageToJson (info) {
  // console.log("userInfo:", info);
  if (info || info !== null) {
      const sk = process.env.VUE_APP_SK;
      const iv = process.env.VUE_APP_SK;
      const decrypted = CryptoJS.AES.decrypt(info, sk, {iv: CryptoJS.enc.Utf8.parse(iv)});
      // console.log("Decrypted:", JSON.parse(decrypted.toString(CryptoJS.enc.Utf8)));
      return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
  } else {
      return "";
  }
}