import {defineStore} from 'pinia';
import { warningAlert } from "@/utils/alert";
import AccountService from "@/modules/management/account/service/accountSevice";
import { decryptMessageToJson } from "@/state/encrypt";

export const useTablesStore = defineStore('tables', {
    state: () => ({
        accounts: [],
        
    }),
    actions: {
        getAccountsList(unitName, departmentId, activedStatus) {
          try {
            const companyId = decryptMessageToJson(sessionStorage.getItem("userInfo"))?.companyId;
            const api = new AccountService();
            if (unitName === ""){
                api.getAccountListByCompDeptAndStatus({ companyId: companyId, departmentId: departmentId, activedStatus: activedStatus }).then((res) => {
                  if (res.responseCode === "000000") {
                      this.accounts = res.dataSet;
                  } else {
                    warningAlert("查無資料");
                  }
            }).catch((err) => {
                console.log("catch error");
            });
          } else {
                api.getAccountListByCompDeptAndStatus({ companyId: companyId, unitName: unitName, departmentId: departmentId, activedStatus: activedStatus }).then((res) => {
                  if (res.responseCode === "000000") {
                      this.accounts = res.dataSet;
                  } else {
                    warningAlert("查無資料");
                  }
            }).catch((err) => {
                console.log("catch error");
            });
          }
        } catch (error) {
            this.dashboard = [];
        }
        },
    },
});
