import { userService } from '@/modules/auth/service/user.service';
import { encryptedUserInfo, decryptMessageToJson } from '@/state/encrypt';
import { authNotAllowedAlert } from "@/utils/alert";
import CryptoJS from "crypto-js";
export const state = {
    currentToken: localStorage.getItem('cjt'),
    userInformation: localStorage.getItem('userInfo'),
    sessionExpired: localStorage.getItem('se')
}

// 設定使用者登入資訊
export const mutations = {
    SET_CURRENT_TOKEN(state, newValue) {
        state.currentToken = saveState('cjt', newValue);
    },
    SET_USER_INFO(state, newValue) {
        state.userInformation = saveState('userInfo', newValue);
    },
    SET_SESSION_EXPIRED(state, newValue) {
        state.sessionExpired = saveState('se', newValue);
    }
}

// 獲取使用者登入狀態(true/false)
export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.currentToken
    },

    jwtToken(state) {
        return state.currentToken;
    },

    async userInfo(state) {
        const user = JSON.parse(sessionStorage.getItem("user"));
        const request = {
            account: user.username
        }
        const res = await getUserInfo({commit: () => {}}, request);
        if (res.status === 200) {
            // 觸發 getUserInfo 後若成功，使用者資訊會存入sessionStorage，因此這裡直接取出使用者資訊
            return decryptMessageToJson(sessionStorage.getItem('userInfo'));
        } else {
            window.location.href = window.location.origin + '/login';
            return;
        }
    },

    isSessionExpired(state) {
        return state.sessionExpired;
    }
}

// 調用方法
export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    // eslint-disable-next-line no-unused-vars
    // init({state, dispatch}) {
    //     dispatch('validate')
    // },

    // Logs in the current user.
    async logIn({commit}, req) {
        const user = (sessionStorage.getItem('user'));
        if (user) {
            console.log("logIn")
            commit('SET_CURRENT_TOKEN', user.token);
            commit('SET_SESSION_EXPIRED', 0);
            return Promise.resolve({status: 200, data: "登入成功"});
        }else{
            console.log("JWT token不存在，重新導向到登入頁");
            commit('SET_SESSION_EXPIRED', null);
            // window.location.href = process.env.VUE_APP_ESG_URL + '/login';
            window.location.href = window.location.origin + '/login';
            return Promise.reject({status: 403, data: "Keycloak驗證異常"});
        }

        // 2024/10 以下為TCFD合併至ESG平台前，按下登入時觸發的程式碼，登入時會攜帶統編、帳密打API取得JWT token
        // return userService.getJWTToken(req).then(
        //     async res => {
        //         if (res.data) {
        //             // step1 儲存token到localStorage(未來可以看要不要放在cookie)
        //             commit('SET_CURRENT_TOKEN', res.data);
        //             commit('SET_SESSION_EXPIRED', 0);
        //             console.log("JWT TOKEN:", res.data)
        //             // step2 取得使用者資訊
        //             const request = {
        //                 taxId: req.taxId,
        //                 account: req.username
        //             }
        //             return await getUserInfo({commit}, request);
        //         } else {
        //             console.log("Keycloak驗證異常");
        //             commit('SET_SESSION_EXPIRED', null);
        //             return Promise.reject({status: 403, data: "Keycloak驗證異常"});
        //         }
        //     },
        //     error => {
        //         // commit('loginFailure', error);
        //         // dispatch('notification/error', error, {root: true});
        //         console.log("登入失敗!")
        //         commit('SET_SESSION_EXPIRED', null);
        //         // return {status: 500, data: error};
        //         return Promise.reject({status: 500, data: error})
        //     }
        // )
    },

    // Logs out the current user.
    logOut({commit}) {
        commit('SET_CURRENT_TOKEN', null);
        commit('SET_USER_INFO', null);
        commit('SET_SESSION_EXPIRED', null);
        userService.logout();
    },

    // register the user
    // todo 待修改
    register({commit, dispatch, getters}, {username, password, email, taxId} = {}) {
        // if (getters.loggedIn) return dispatch('validate')
        const userInfo = {
            username: username,
            password: password,
            email: email,
            taxId: taxId
        }
        return userService.register(userInfo).then((response) => {
            const user = response
            commit('SET_CURRENT_TOKEN', user, null, null)
            return user
        });
    },


    setSessionExpired({commit}) {
        commit('SET_SESSION_EXPIRED', 1);
    }

    // Validates the current user's token and refreshes it
    // with new data from the API.
    // eslint-disable-next-line no-unused-vars
    // validate({commit, state}) {
    //     if (!state.currentToken) return Promise.resolve(null)
    //     // const user = getFirebaseBackend().getAuthenticatedUser();
    //     const user = userService.login();
    //     commit('SET_CURRENT_TOKEN', user)
    //     return user;
    // },
}

// ===
// Private helpers
// ===

const saveState = (key, state) => {
    if(state === null){
        window.localStorage.removeItem(key);
        return null;
    }
    const sk = process.env.VUE_APP_SK;
    const iv = process.env.VUE_APP_SK;
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(state), sk, {iv: CryptoJS.enc.Utf8.parse(iv)});
    const ciphertext = encrypted.toString();
    window.localStorage.setItem(key, ciphertext)
    return ciphertext;
}


const getUserInfo = ({commit}, req) => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    const request = {
        account: user.userName
    }
    return userService.getUserInfo(request).then(
        res => {
            // 取得使用者資訊
            const data = res.dataSet;
            // console.log("使用者資訊:", data);
            if(data){
                const userInfo = {
                    account: data.account,
                    accountId: data.accountSettingId,
                    activedStatus: data.activedStatus,
                    firstInStatus: data.firstInStatus,
                    username: data.chineseName,
                    role: data.mainIdentity,
                    roleId: data.roleId,
                    plan: data.planType,
                    depId: data.departmentId,
                    unitName: data.unitName,
                    depName: data.departmentName,
                    companyId: data.companyId,
                    taxIdNumber: data.taxIdNumber
                }
                commit('SET_USER_INFO', userInfo);
                // 將使用者資訊加密，並存入sessionStorage
                encryptedUserInfo('userInfo', userInfo);
                return Promise.resolve({status: 200, data: data})
            }else {
                commit('SET_CURRENT_TOKEN', null);
                // 取得使用者資訊失敗，data為空或不存在，重新導向到登入頁
                authNotAllowedAlert();
                return Promise.reject({status: 403, data: "系統異常，獲取使用者資訊失敗!"});
            }
        },
        error => {
            // 取得使用者資訊失敗
            return Promise.reject({status: error?.response?.status || 400 , data: "系統異常，獲取使用者資訊失敗!"});
        }
    )
}
