export const state = {
  layoutType: 'horizontal',
  layoutWidth: 'fluid',
  topbar: 'dark',
  mode: 'light',
  position: 'fixed',
  preloader: 'disable',
  bodybg: 'none',
  visibility: 'show'
};
export const mutations = {
  CHANGE_LAYOUT(state, layoutType) {
    state.layoutType = layoutType;
  },
  CHANGE_LAYOUT_WIDTH(state, layoutWidth) {
    state.layoutWidth = layoutWidth;
  },
  CHANGE_TOPBAR(state, topbar) {
    state.topbar = topbar;
  },
  CHANGE_MODE(state, mode) {
    state.mode = mode;
  },
  CHANGE_POSITION(state, position) {
    state.position = position;
  },
  CHANGE_PRELOADER(state, preloader) {
    state.preloader = preloader;
  },
  CHANGE_BODYBG(state, bodybg) {
    state.bodybg = bodybg;
  },
  CHANGE_VISIBILITY(state, visibility) {
    state.visibility = visibility;
  },
};
export const actions = {
  changeLayoutType({ commit }, { layoutType }) {
    commit('CHANGE_LAYOUT', layoutType);
    document.body.removeAttribute("style");
  },
  changeLayoutWidth({ commit }, { layoutWidth }) {
    commit('CHANGE_LAYOUT_WIDTH', layoutWidth);
  },
  changeTopbar({ commit }, { topbar }) {
    commit('CHANGE_TOPBAR', topbar);
  },
  changeMode({ commit }, { mode }) {
    commit('CHANGE_MODE', mode);
  },
  changePosition({ commit }, { position }) {
    commit('CHANGE_POSITION', position);
  },
  changePreloader({ commit }, { preloader }) {
    commit('CHANGE_PRELOADER', preloader);
  },
  changeBodybg({ commit }, { bodybg }) {
    commit('CHANGE_BODYBG', bodybg);
  },

  changeVisibility({ commit }, { visibility }) {
    commit('CHANGE_VISIBILITY', visibility);
  }
};