import Swal from "sweetalert2";

const Toast = Swal.mixin({
  toast: true,
  position: "top",
  showConfirmButton: false,
  timer: 10000,
  timerProgressBar: true,
  didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
  },
});

export function addSuccessAlert() {
    Toast.fire({
        icon: "success",
        title: "資料新增成功"
    });
}

export function editSuccessAlert() {
  Toast.fire({
      icon: "success",
      title: "資料更新成功"
  });
}

export function deleteSuccessAlert() {
  Toast.fire({
      icon: "success",
      title: "資料刪除成功"
  });
}

export function errorAlert() {
  Toast.fire({
      icon: "error",
      title: "操作失敗"
  });
}

export function customizeErrorAlert(err, message) {
  if (err && err?.code === 'ECONNABORTED') {
    Toast.fire({
      icon: "error",
      title: message
    });
  } else {
    Toast.fire({
      icon: "error",
      title: message
    });
  }
}

export function customizeSuccessAlert(message) {
  Toast.fire({
      icon: "success",
      title: message
  });
}

export function warningAlert(message) {
  Toast.fire({
      icon: "warning",
      title: message
  });
}

export function loginStatusAlert() {
  Swal.fire({
      icon: "error",
      title: "登入失敗",
  });
}

export function loginFirstInStatusAlert() {
  Swal.fire({
      icon: "warning",
      title: "為了帳戶安全，請更新密碼"
  });
}

export function authNotAllowedAlert() {
  Swal.fire({
    icon: "error",
    title: "您的帳號未啟用此服務!",
  }).then(() => {
    // 當使用者按下「OK」後，將使用者導回模組選擇頁面
    // window.location.href = process.env.VUE_APP_ESG_URL;
    window.location.href = window.location.origin;
  });
}