import axios from "axios";
import api from "@/apiConfig/axiosConfig";

const keycloakAPI = axios.create({
    baseURL: process.env.VUE_APP_PROXY_AUTH_URL,
    headers: {"Content-Type": "application/x-www-form-urlencoded"},
    timeout: 5000,
});

const getJWTToken = async (req) => {
    // console.log("get token", req);
    return await keycloakAPI.post(process.env.VUE_APP_AUTH_PATH, req);
};

const getUserInfo = async (req) => {
    const result = await api.post('/accountSetting/infoAccountWithRole', req);
    return result.data;
}

const getAccountByAccount = async (req) => {
    const result = await api.post("/accountSetting/findByExample", req);
    return result.data;
}

const logout = () => {
    // 將所有sessionStorage清除
    sessionStorage.clear();
};
const register = (userInfo) => {
    return userInfo;
};

export const userService = {
    getJWTToken,
    logout,
    register,
    getUserInfo,
    getAccountByAccount,
};
