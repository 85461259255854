import axios from 'axios';
import router from "@/router";
import store from "@/state/store";

const staticPath = "/api/tcfd/v1";
const api = axios.create({
    baseURL: staticPath,
    timeout: 5000,
});

api.interceptors.request.use(config => {
        const user = JSON.parse(sessionStorage.getItem('user'));
        if (user && user.token) {
            config.headers.Authorization = "Bearer " + user.token;
        } else {
            // 沒有token call api會console
            // 登入時需要call api 判斷帳號的狀態，所以不印錯誤
            console.log("")
        }
        config.headers["Content-Type"] = "application/json";
        return config;
    },
)


api.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response?.status && error.response.status === 401) {
            store.dispatch('auth/setSessionExpired').then(() => {
                console.log('401 error');
            });
            return Promise.reject(error);
        } else if (error.response?.status && error.response.status === 403) {
            console.log('403 error');
            return Promise.reject(error);
        } else if (error.response?.status && error.response.status === 404) {
            console.log('404 error');
            router.push({name: '404'});
        } else if (error.code ==='ECONNABORTED') {
            console.log('連線超時');
            return Promise.reject(error);
        } else if (error.response?.status && error.response.status === 304) {
            console.log('CACHE NOT MODIFIED');
        } else {
            console.log('others error');
            router.push({name: '500'})
        }
    }
)

export default api;